import { Link } from "@material-ui/core";
import { green, grey, red } from '@material-ui/core/colors';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import RefreshIcon from '@material-ui/icons/Refresh';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import React from "react";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex"
    },
    tableCellDefault: {
        borderWidth: "1px",
        borderStyle: 'solid',
        borderColor: 'gray',
        padding: "5px",
        maxWidth: "70px"
    }
}));

export default function DpTRTable(props) {
    const classes = useStyles();
    const regions = ["eu-central-1", "us-east-1", "cn-north-1"]
    const envs = [
        //{ "key": "sad", "value": "Dev" },
        { "key": "sas", "value": "Stage" },
        { "key": "sa", "value": "Prod" }
    ]

    const getColor = (result) => {
        if (result == "SUCCESS") {
            return { color: green[500] }
        } else if (result == "FAILURE") {
            return { color: red[500] }
        } else {
            return { color: grey[500] }
        }
    }

    const getCellClass = (key) => {
        switch (key) {
            case "ami_name":
                return classes.roottableCellAmiName
            case "deploy_time":
            case "deployed_time":
            case "recovery_of":
                return classes.tableCellTime
            case "app_version":
                return classes.tableCellAppVersion
            case "deploy_user":
                return classes.tableCellUser
            case "deployed_region":
                return classes.tableCellRegion
            case "deployed_env":
                return classes.tableCellEnv
            default:
                return classes.tableCellDefault
        }
    }

    const cellDpRule = (key, source) => {
        if (source !== undefined) {
            switch (key) {
                case "cd_test":
                    let data = source[key]
                    if (data !== undefined && data !== "") {
                        let cd_result = data.split("|")
                        return (
                            <Link href={cd_result[1]}>
                                <Brightness1Icon fontSize="large" style={getColor(cd_result[0])} />
                            </Link>
                        )
                    }
                    break
                case "retry":
                    if("cd_test" in source) {
                        let data = source["cd_test"]
                        if (data !== undefined && data !== "") {
                            let cd_result = data.split("|")
                            if (cd_result[0] === "FAILURE") {
                                return (<Link href={cd_result[1]}>
                                    <RefreshIcon fontSize="small"/>
                                </Link>)
                            }
                        }
                    }
                    break
                case "deployed_time": {
                    let data = source[key]
                        console.log(data)
                        if (data !== undefined && data !== "") {
                            return (
                                <Alert icon={false} severity="success">
                                    {data}
                                </Alert>
                            )
                        }
                    }
                    break
                case "ami_name": {
                    let data = source[key]
                        let version = ""
                        if("app_version" in source) {
                            version = source["app_version"]
                        }
                        if (data !== undefined && data !== "") {
                            return (
                                <Alert icon={false} severity="info">
                                    {data}
                                    <p>({version.replace(/\|/gi, " ")})</p>
                                </Alert>
                            )
                        }
                    }
                    break
                default:
                    if(key in source) {
                        let data = source[key]
                        return data
                    }
            }
        }
    }

    const makeKey = (service, env, region) => {
        return service + '_' + env + '_' + region
    }

    return (
        <TableContainer component={Paper}>
            <Table id="table" className={classes.table} aria-label="spanning table">
                <TableHead id='header'>
                    <TableRow id='header_row'>
                        <TableCell align="center" colSpan={1}>
                            Service
                        </TableCell>
                        {regions.map(region =>
                            <TableCell align="center" colSpan={4}>
                                {region}
                            </TableCell>
                        )}
                    </TableRow>
                    <TableRow id='header_row'>
                        <TableCell align="center" colSpan={1}>
                            Name
                        </TableCell>
                        {regions.map(_ => (
                            envs.map(env => (
                                <TableCell align="center" colSpan={2}>
                                    {env["value"]}
                                </TableCell>
                            ))
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody id='body'>
                    {props.keys.map(key => (
                        <TableRow id={key}>
                            <TableCell align="center" colSpan={1} >
                                {key}
                            </TableCell>
                            {regions.map(region => (
                                envs.map(env => (
                                    <TableCell className={classes.tableCellDefault} align="center" colSpan={2} >
                                        <Typography>{cellDpRule("cd_test", props.data[makeKey(key, env["key"], region)])} {/* {cellDpRule("retry", props.data[makeKey(key, env["key"], region)])} */}</Typography>
                                        <Typography>{cellDpRule("ami_name", props.data[makeKey(key, env["key"], region)])}</Typography>
                                        <Typography>{cellDpRule("deployed_time", props.data[makeKey(key, env["key"], region)])}</Typography>
                                    </TableCell>
                                ))
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

