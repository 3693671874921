import React from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import useClipboard from 'react-hook-clipboard'
import FileCopyIcon from '@material-ui/icons/FileCopy';

export default function EnvDp(props) {
    const [clipboard, copyToClipboard] = useClipboard()

    const classes = makeStyles(theme => ({
        input: {
            width: "90%",
        },
    }))();

    const getEncryptedValue = (region) => {
        for(var i in props.values) {
            if(props.values[i]["region"] === region) {
                console.log(props.values[i])
                return props.values[i]["encrypted data"]
            }
        }
        return ""
    }

    return (
        <Box component="div">
             <TextField
                className={classes.input}
                name={props.region}
                label={props.region}
                value={getEncryptedValue(props.region)}
                InputProps={{
                    readOnly: true,
                }}
                InputLabelProps={{
                    shrink: true,
                }}
                enabled="false"
            />
            <Button color="primary" onClick={() => copyToClipboard(getEncryptedValue(props.region))} >
                <FileCopyIcon/>
            </Button>
        </Box>
    );  
}