

import React, { useRef } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { requestPost } from "../../util/RequestData"
import ServerInfo from "../../backend_info.json"
import { SetParamToUrl } from "../../util/UrlHelper"
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import Permissions from '../../auth/Permissions'

export default function RecordDialog(props) {

    const classes = makeStyles(theme => ({
        root: {
            display: "flex"
        },
        input: {
            width: "300px",
        },
        comment: {
            width: "300px",
            minHeight: "150px",
        }
    }))();

    const getEditField = (id, label, defaultValue, mode, type) => {
        return (
            <div>
                <TextField
                    className={classes.input}
                    name={id}
                    label={label}
                    onChange={handleInputChange}
                    type={type}
                    defaultValue={initValue(id, defaultValue)}
                    InputProps={{
                        readOnly: IsReadOnlyMode(id, mode),
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </div>
        )
    }

    const ReadOnlyModeFields = [
        "service_name",
        "ami_id",
        "ami_name",
        "app_version",
        "deploy_time",
        "deployed_time",
        "deployed_env",
        "deployed_region",
    ]

    const values = useRef({})

    const handleInputChange = e => {
        values.current[e.target.name] = e.target.value
    }

    const IsReadOnlyMode = (key, mode) => {
        if (mode === "new" || Permissions.getInst().hasPermission("dphistory.modify")) {
            return false
        }
        return ReadOnlyModeFields.includes(key)
    }

    const initValue = (key, value) => {
        if (isTimeType(key) && value !== undefined) {
            value = value.replace(" ", "T")
        }
        values.current[key] = value
        return values.current[key]
    }

    function isTimeType(key) {
        if (key === "deploy_time" || key === "deployed_time" || key === "recovery_of") {
            return true
        }
        return false
    }

    const saveClicked = () => {
        const data = props.data
        
        for (const k in values.current) {
            if (values.current[k] !== undefined) {
                if (isTimeType(k)) {
                    data[k] = values.current[k].replace("T", " ")
                } else {
                    data[k] = values.current[k]
                }
            }
        }

        let baseUrl = ServerInfo.dphistory.path_v1_updateHistory
        if (props.mode === "new") {
            baseUrl = ServerInfo.dphistory.path_v1_register
        }

        let url = SetParamToUrl(baseUrl + "?", data)
        requestPost(url, {"comment" : data["comment"]}, response => {
            if (response.status === 200) {
                props.closeRequest()
            }
        })
    }

    const cancelClicked = () => {
        props.closeRequest()
    }

    values.current = {}

    return (
        <Box component="div">
            <Dialog
                open={props.open}
                onClose={props.clickCancel}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Record Detail
                </DialogTitle>
                <DialogContent>
                    {getEditField("service_name", "Service name", props.data["service_name"], props.mode)}
                    {getEditField("ami_id", "AMI ID", props.data["ami_id"], props.mode)}
                    {getEditField("ami_name", "AMI name", props.data["ami_name"], props.mode)}
                    {getEditField("deployed_region", "Deployed region", props.data["deployed_region"], props.mode)}
                    {getEditField("deployed_env", "Deployed env", props.data["deployed_env"], props.mode)}
                    {getEditField("app_version", "App version", props.data["app_version"], props.mode)}
                    {getEditField("deploy_time", "Deploy time", props.data["deploy_time"], props.mode, "datetime-local")}
                    {getEditField("deployed_time", "Deployed time", props.data["deployed_time"], props.mode, "datetime-local")}
                    <Box component="div">
                        <Select
                            name="recovery_mode"
                            onChange={handleInputChange}
                            defaultValue={initValue("recovery_mode", props.data["recovery_mode"])}>
                            <MenuItem value=''>None</MenuItem>
                            <MenuItem value='rollback'>Rollback</MenuItem>
                            <MenuItem value='hotfix'>Hotfix</MenuItem>
                        </Select>
                    </Box>
                    <Box component="div">
                        <TextField
                            className={classes.input}
                            name="recovery_of"
                            label="Recovery of"
                            onChange={handleInputChange}
                            defaultValue={initValue("recovery_of", props.data["recovery_of"])}
                            type="datetime-local"
                            InputProps={{
                                readOnly: IsReadOnlyMode("recovery_of", props.mode),
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>
                    <TextareaAutosize
                        className={classes.comment}
                        name="comment"
                        defaultValue={props.data["comment"]}
                        aria-label="empty textarea"
                        onChange={handleInputChange}
                        placeholder="Comment" />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={cancelClicked} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={saveClicked} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
