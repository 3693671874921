import { LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useRef } from "react";
import ServerInfo from "../../backend_info.json";
import GlobalConfig from "../../config.json";
import { requestGet } from "../../util/RequestData";
import { SetParamToUrl } from '../../util/UrlHelper';
import DpTRTable from './DpTRTable';



const useStyles = makeStyles(theme => ({
    root: {
        display: "flex"
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    table: {
        marginTop: "100px"
    }
}));


export default function TestBoard() {
    const classes = useStyles();
    const dataloaded = useRef(false) 
    const [dpData, setDpData] = React.useState({})

    const serviceList = GlobalConfig['testboard']['serviceList']

    const initValues = () => {
    }
    
    const makeServiceMap = (data) => {
        let services = {}
        for(let i in data) {
            let serviceName = data[i]['service_name']+ '_' + data[i]['deployed_env'] + '_' + data[i]['deployed_region'] 
            if (serviceName in services) {
                if (services[serviceName]['deployed_time'] < data[i]['deployed_time']) {
                    services[serviceName] = data[i]
                }
            } else {
                services[serviceName] = data[i]
            }
        }
        setDpData(services)
    }

    useEffect(() => {
        requestGet(SetParamToUrl(ServerInfo.dphistory.path_v1_getFilteredHistory + "?", {
            "service_name" : serviceList
        }), null, response => {
            dataloaded.current = true
            if (response.status === 200) {
                makeServiceMap(response.data)
            }
        })
    }, []);

    initValues()

    return (
        <form className={classes.container} noValidate>
            {!dataloaded.current && <LinearProgress/>}
            <DpTRTable className={classes.table} keys={serviceList} data={dpData} />
        </form>
    );
}
