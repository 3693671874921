import OidcInfo from "./oidc_info.json";
import ServerInfo from "../backend_info.json";
import { requestPost } from "../util/RequestData";
import Permissions from "./Permissions"

class Auth
{
    private static _instance: Auth;
    private mUserInfo = new Map<string, any>();
    private constructor()
    {

    }

    public static getInst()
    {
        if (Auth._instance === undefined) {
            Auth._instance = new this();
        }
        return Auth._instance
    }

    /**
     * getUserInfo
     */
    public getUserInfo() : Map<string, any> {
        return this.mUserInfo
    }

    public setLoginInfo(params : any) {
        this.mUserInfo.set("email", params["email"])
        this.mUserInfo.set("user", params["name"])
        this.mUserInfo.set("permission", params["mbrOf"])
        this.mUserInfo.set("token", params["token"])        
    }

    public getToken() :string {
        return this.mUserInfo.get("token")
    }

    public getPermissions() :Array<string>{
        return this.mUserInfo.get("permission")
    }

    public getUser() : string{
        return this.mUserInfo.get("user")
    }
    
    public getEmail() : string{
        return this.mUserInfo.get("email")
    }

    /**
     * requestAuth
     */
    public requestAuth() {
        let url = OidcInfo.provider_url +  "?response_type=code&client_id=" + OidcInfo.client_id + "&redirect_uri=" + OidcInfo.login_redirect_url + "&scope=access&state=L9vxOwpCAdILAKnb"
        window.location.assign(url)
    }

    /**
     * requestLogin
     */
    public requestLogin(params :any, response :any) {
        let url = ServerInfo.inz.path_v1_authorization +  "?redirect_uri=" + OidcInfo.login_redirect_url + "&code=" + params.get('code')
        requestPost(url, {}, response)
    }

    /**
     * logOut    */
    public logOut() {
        let url = OidcInfo.session_endpoint_url + "?redirect_uri=" + OidcInfo.logout_redirect_url
        window.location.assign(url)
        Permissions.getInst().clearCache()
    }
}

export default Auth