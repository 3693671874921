import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PropTypes from "prop-types"
import RecordEditButton from "../../components/RecordEditButton"
import Typography from '@material-ui/core/Typography';
import Permissions from "../../auth/Permissions";
import Box from '@material-ui/core/Box';
import Auth from "../../auth/Auth";
import Tooltip from '@material-ui/core/Tooltip';
import { Button, Link } from "@material-ui/core";
import MessageIcon from '@material-ui/icons/Message';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import { green, red, grey } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex"
    },
    tableHeadCell: {
        background : "black",
        color : "White"
    },
    tableCellAmiName: {
    },
    tableCellDefault: {
        padding: "5px",
        maxWidth : "70px"
    },
    tableCellTime: {
        padding: "5px",
        maxWidth : "60px"
    },
    tableCellEnv: {
        padding: "5px",
        maxWidth : "10px"
    },
    tableCellRegion: {
        padding: "5px",
        maxWidth : "30px"
    },
    tableCellAppVersion: {
        padding: "5px",
        maxWidth : "60px"
    },
    tableCellUser: {
        padding: "5px",
        maxWidth : "100px"
    },
    tableCellMode: {
        padding: "5px",
        maxWidth : "70px"
    },
    editHideMode: {
        display : "none"
    },
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

const getBgColor = (row, style) => {
    if(row.recovery_mode === "hotfix") {
        style['background'] = "BlanchedAlmond"
        style['color'] = "black"
    } else if(row.recovery_mode === "rollback") {
        style['background'] = "DarkSlateGray"
        style['color'] = "AliceBlue"
    }
    return style
}

const isVisible = (user) => {
    if (Auth.getInst().getEmail() === user || Permissions.getInst().hasPermission("dphistory.modify")) {
        return 'visibile'
    }
    return 'hidden'
}

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);
  

export default function DpLogTable(props) {
    const classes = useStyles();

    const getColor = (result) => {
        if (result == "SUCCESS") {
            return { color: green[500] }
        } else if (result == "FAILURE") {
            return { color: red[500] }
        } else {
            return { color: grey[500] }
        }
    }

    const getCellClass = (key) => {
        switch (key) {
            case "ami_name":
                return classes.roottableCellAmiName
            case "deploy_time":
            case "deployed_time":
            case "recovery_of":
                return classes.tableCellTime
            case "app_version":
                return classes.tableCellAppVersion
            case "deploy_user":
                return classes.tableCellUser
            case "deployed_region":
                return classes.tableCellRegion
            case "deployed_env":
                return classes.tableCellEnv
            default:
                return classes.tableCellDefault
        }
    }

    const cellDpRule = (key, data) => {
        switch(key) {
            case "app_version":
                return data.replace(/\|/gi, " ")
            case "deploy_user":
                return data.replace("@", " @").replace("samsung.com", "-")
            case "comment":
                if (data !== undefined && data !== "") {
                    return( 
                        <HtmlTooltip className={classes.tooltip}
                        title={
                            <React.Fragment>
                                <Typography style={ {whiteSpace: 'pre-line'} } color="inherit">{data}</Typography>
                            </React.Fragment>
                        }
                        >
                        <Button><MessageIcon/></Button>
                        </HtmlTooltip>
                    )
                }
                return data
            case "cd_test":
                if (data !== undefined && data !== "") {
                    let cd_result = data.split("|")
                    return(
                        <Link href={cd_result[1]}>
                            <Brightness1Icon fontSize="large" style={getColor(cd_result[0])}/>
                        </Link>
                    )
                }
                return data
            default:
                return data
        }
    }

    return (
        <TableContainer component={Paper}>
            <Table id="table" className={classes.table} aria-label="Raw data">
                <TableHead id='header'>
                    <TableRow id='header_row'>
                        <TableCell className={classes.tableHeadCell} />
                        {props.keys.map(key => 
                            <TableCell id={key} className={classes.tableHeadCell} align="center">
                                <Typography>{key}</Typography>
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody id='body'>
                    {props.data.map(row => (
                        <TableRow id={row['deploy_time']}>
                            <Box component="div" id={row['deploy_time'] + 'edit'} class="MuiTableCell-root MuiTableCell-body MuiTableCell-alignCenter" style={getBgColor(row, {})}>
                                <RecordEditButton visibility={isVisible(row['deploy_user'])} align="center" onClick={props.editHandler} data={row}/>
                            </Box>
                            {props.keys.map(key => 
                                <TableCell className={getCellClass(key)} id={key + 'id'} align="center" style={getBgColor(row, {})} >
                                    <Typography>{cellDpRule(key, row[key])}</Typography>
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

DpLogTable.propTypes = {
    keys: PropTypes.array,
    data: PropTypes.array,
    editHandler: PropTypes.func
};
