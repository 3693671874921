
export function SetParamToUrl(url :string, params :any) {

    for (let k in params) {
        if (params[k] !== undefined) {
            if (Array.isArray(params[k])) {
                console.log(params[k])
                for (let i in params[k]) {
                    url = url + k + "=" + params[k][i] + "&"
                }
            } else {
                url = url + k + "=" + params[k] + "&"
            }
        }
    }
    return url
}