import React, { useRef } from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import useClipboard from 'react-hook-clipboard'
import ResultDp from './ResultDp'
import ServerInfo from "../../backend_info.json"

export default function Kmsencrypt() {
    const [clipboard, copyToClipboard] = useClipboard()
    const plainText = useRef("")
    const serviceName = useRef("")

    const classes = makeStyles(theme => ({
        root: {
            display: "flex"
        },
        service_input: {
            width: "10%",
        },
        input: {
            marginLeft: "5px",
            width: "62%"
        },
    }))();

    const onChangedPlainText = (o) => {
        plainText.current = o.target.value
    }

    const onChangedServiceName = (o) => {
        serviceName.current = o.target.value
    }

    const getPlainText = () => {
        return plainText.current
    }

    const getServiceName = () => {
        return serviceName.current
    }

    const encryptEnv = [
        {
            "env" : "Preprod",
            "regions" : [
                "us-east-1",
                "eu-central-1",
            ],
            "encrypt_endpoint" : ServerInfo.encrypt.path_preprod,
            "getPlainText" : getPlainText,
            "getServiceName" : getServiceName
        },
        {
            "env" : "Prod",
            "regions" : [
                "us-east-1",
                "eu-central-1",
            ],
            "encrypt_endpoint" : ServerInfo.encrypt.path_prod,
            "getPlainText" : getPlainText,
            "getServiceName" : getServiceName
        },
        {
            "env" : "Preprod Chinass",
            "regions" : [
                "cn-north-1"
            ],
            "encrypt_endpoint" : ServerInfo.encrypt.path_preprod_cn,
            "getPlainText" : getPlainText,
            "getServiceName" : getServiceName
        },
        {
            "env" : "Prod China",
            "regions" : [
                "cn-north-1"
            ],
            "encrypt_endpoint" : ServerInfo.encrypt.path_prod_cn,
            "getPlainText" : getPlainText,
            "getServiceName" : getServiceName
        }
    ]

    return (
        <Box component="div">
            <TextField
                className={classes.service_input}
                name="servicename"
                label="Service name"
                defaultValue=""
                onChange={onChangedServiceName}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <TextField
                className={classes.input}
                name="Plain property"
                label="Plain property"
                defaultValue=""
                onChange={onChangedPlainText}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            {encryptEnv.map(data => (
                <ResultDp data={data} />
            ))}
        </Box>
    );  
}