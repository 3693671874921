import React, { useRef, useEffect } from "react";
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import useClipboard from 'react-hook-clipboard'
import EnvDp from './EnvDp'
import { requestPost } from "../../util/RequestData"
import CircularProgress from '@material-ui/core/CircularProgress';

export default function ResultDp(props) {
    const [clipboard, copyToClipboard] = useClipboard()
    const [plainText, setPlainText] = React.useState("")
    const [encryptedValues, setEncryptedValues] = React.useState({})
    const [loading, setLoading] = React.useState(false)

    const classes = makeStyles(theme => ({
        result: {
            width: "70%",
            margin: "1rem",
            padding: "1rem",
            paddingLeft: "3rem"
        },
        plainInfo: {
            width: "50%",
            marginLeft: "1rem"
        }
    }))();

    const encryptRequest = () => {
        setPlainText(props.data["getPlainText"]())
    }

    useEffect(() => {
        if(plainText !== "") {
            setLoading(true)            
            setEncryptedValues({})
            requestPost(props.data["encrypt_endpoint"], {
                "servicename" : props.data["getServiceName"](),
                "plaintext" : plainText
            }, response => {
                setLoading(false)
                if (response.status === 200) {
                    setEncryptedValues(response.data)
                    console.log(response.data)
                }
            }, {
                "Content-Type": "application/json; charset=utf-8"
            })
        }
    }, [plainText]);

    return (
        <Box className={classes.result} component="div" border={2} borderColor="primary.main" borderRadius="borderRadius" borderRadius={16} >
            <Button variant="outlined" color="primary"  onClick={encryptRequest}>
                {props.data["env"]}
            </Button>
            <TextField
                className={classes.plainInfo}
                InputProps={{
                    readOnly: true,
                }}
                value={plainText}
            />
            { loading && <CircularProgress size={15} />}            
            {props.data["regions"].map(region => (
                <EnvDp region={region} values={encryptedValues} />
            ))}
        </Box>
    );  
}