



import GlobalConfig from "../config.json";
import Auth from "./Auth"

class Permissions
{
    private static _instance: Permissions;
    private confirmedPermission = new Map<string, boolean>();
    private constructor()
    {
    }

    public clearCache() {
        this.confirmedPermission = new Map<string, boolean>()
    }

    public static getInst()
    {
        if (Permissions._instance === undefined) {
            Permissions._instance = new this();
        }
        return Permissions._instance
    }

    public hasPermission(permission :string) : boolean {

        let user = Auth.getInst().getEmail()
        let group = Auth.getInst().getPermissions()

        let cache = this.confirmedPermission.get(permission)
        if (cache !== undefined) {
            return cache
        }

        let perDepth = permission.split('.')
        let perTree = GlobalConfig['permissions']
        let monkey
        for (let per in perDepth) {
            monkey = this.searchPermission(monkey === undefined ? perTree : monkey, perDepth[per])
            if (monkey === undefined) {
                return false
            }
        }

        let authorizedUsers = monkey['user']
        let authorizedGroups = monkey['group']
        
        if (authorizedGroups !== undefined) {
            for (let i in group) {
                if(authorizedGroups.includes(group[i])) {
                    this.confirmedPermission.set(permission, true)
                    return true
                }
            }
        }

        if (authorizedUsers !== undefined && authorizedUsers.includes(user)) {
            this.confirmedPermission.set(permission, true)
            return true
        }
        return false
    }

    private searchPermission(pivot :any, name :string) :any {
        if ( pivot[name] !== undefined) {
            return pivot[name]
        }
        return undefined
    }
}

export default Permissions
