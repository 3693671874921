
import HistoryIcon from '@material-ui/icons/History';
import PersonIcon from '@material-ui/icons/Person';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ListAltIcon from '@material-ui/icons/ListAlt';
import DeploymentHistory from './history/DeploymentHistory';
import TestBoard from './testboard/TestBoard';
import Kmsencrypt from './kmsencrypt/Kmsencrypt';
import UserInfo from './about/UserInfo'

const DashBoardMenus = [
    {
      "title": "KMS encryptssss",
      "icon": VpnKeyIcon,
      "view": Kmsencrypt,
      "permission": "kmsencrypt"
    },
    {
      "title": "Deployment Board",
      "icon": ListAltIcon,
      "view": TestBoard,
      "permission": "testboard"
    },
    {
      "title": "Deployment History",
      "icon": HistoryIcon,
      "view": DeploymentHistory,
      "permission": "dphistory"
    },
    {
      "title": "UserInfo",
      "icon": PersonIcon,
      "view": UserInfo
    }
]

export {DashBoardMenus}