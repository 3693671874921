
import React from "react";
import RefreshIcon from '@material-ui/icons/Refresh';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import { green, blue } from '@material-ui/core/colors';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    display: 'inline-block',
    paddingLeft: '10px',
  },
  wrapper: {
    position: 'relative',
  },
  buttonSuccess: {
    position: 'absolute',
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonInvalid: {
    position: 'absolute',
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  }
}));


export default function RefreshButton(props) {
  const classes = useStyles();

  const buttonClassname = clsx({
    [classes.buttonSuccess]: props.dataValid,
    [classes.buttonInvalid]: !props.dataValid
  });

  return (
    <Box component="div" className={classes.root}>
      <Box component="div" className={classes.wrapper}>
        <Fab
          aria-label="save"
          color="primary"
          className={buttonClassname}
          onClick={props.onClick}
        >
        {props.dataValid ? <CheckIcon /> : <RefreshIcon />}
        </Fab>
        { props.requesting && <CircularProgress size={68} className={classes.fabProgress} />}
      </Box>
    </Box>
  )
}

