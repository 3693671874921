import React from "react";
import { BrowserRouter, Route } from 'react-router-dom';

import "./App.css";

import Main from "./views/Main";
import Login from "./views/auth/Login"
import Authorizing from "./views/auth/Authorizing"

function App() {
    return (
      <BrowserRouter>
        <div className="container">
          <Route path="/dashboard" component={Main} />
          <Route exact={true} path="/authorizing" component={Authorizing} />
          <Route exact={true} path="/" component={Login} />
        </div>
      </BrowserRouter>
    )
}

export default App;
