import React, { Component } from 'react';
import Auth from './Auth.ts'
import { withRouter } from 'react-router-dom'
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import IconButton from "@material-ui/core/IconButton";

class Logout extends Component {

  logout() {
    Auth.getInst().logOut()
  }

  render() {
    return (
      <IconButton color="inherit" onClick={ () => this.logout() }>
        <MeetingRoomIcon />
      </IconButton>
    );
  }
}
export default withRouter(Logout);