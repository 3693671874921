
import React from "react";
import Chip from '@material-ui/core/Chip';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from "prop-types"
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
}));

export default function CategoryFilter(props) {
    const classes = useStyles();
    const theme = useTheme();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    function getStyles(name, personName, theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    return (
        <Box component="div">
            {props.items.map(item =>
                <FormControl key={item.id} className={classes.formControl}>
                    <InputLabel id={item.id}>{item.name}</InputLabel>
                    <Select
                        labelId={item.id}
                        id={item.id}
                        multiple={item.multiple}
                        value={item.selectedItems}
                        onChange={item.onChangeListener}
                        input={<Input id="select-chip" />}
                        renderValue={selected => (
                            <div className={classes.chips}>
                                {selected.map(value => (
                                    <Chip key={value} label={value} className={classes.chip} />
                                ))}
                            </div>
                        )}
                        MenuProps={MenuProps}
                    >
                        {item.itemList.map(name => (
                            <MenuItem key={name} value={name} style={getStyles(name, item.selectedItems, theme)}>
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
        </Box>        
    )

}

CategoryFilter.propTypes = {
    items: PropTypes.array,
};
