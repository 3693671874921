import React from 'react';
import Auth from "../../auth/Auth.ts";

export default function UserInfo() {

    return (
        <div className="UserInfo">
            <p>Name: {Auth.getInst().getUser()}</p>
            <p>Email: {Auth.getInst().getEmail()}</p>
            <p>Permissions: {Auth.getInst().getPermissions()}</p>
        </div>
    );  
}