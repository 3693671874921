
import React from "react";
import EditIcon from "@material-ui/icons/Edit";


export default function RecordEditButton(props) {
  
    const clickHandler = () => {
      props.onClick(props.data)
    }

    return (
      <EditIcon visibility={props.visibility} onClick={clickHandler}/>
    ) 
  }
  