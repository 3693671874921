import { Canvas } from 'react-canvas-js'
import React from "react";
import CanvasJSReact from '../../canvasjs/canvasjs.react';
import { StepLabel } from '@material-ui/core';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function DpLogChart(props) {

    const options = {
        animationEnabled: true,
        theme: "light1",
        height: 700,
        dataPointWidth: 15,
        axisX: {
            interval: 1,
            labelFontSize: 17,
        },
        axisY: {
            interval: 1,
            labelFontSize: 17,
        },
        toolTip: {
            shared: true
        },
        legend:{
            cursor: "pointer",
            fontSize: 17,
        },
        data: [{
            type: "stackedBar",
            color: "#3f51b5",
            name: "Normal",
            showInLegend: "true",
            toolTipContent: "<b>{name}:</b> {y} (#percent%)",
            dataPoints: props.data['normal']
        },
        {
            type: "stackedBar",
            color: "#629D9D",
            name: "Rollback",
            showInLegend: "true",
            toolTipContent: "<b>{name}:</b> {y} (#percent%)",
            dataPoints: props.data['rollback']
        },
        {
            type: "stackedBar",
            name: "Hotfix",
            color: "#FBBC5D",
            showInLegend: "true",
            toolTipContent: "<b>{name}:</b> {y} (#percent%)",
            dataPoints: props.data['hotfix']
        }]
    }

    return (
		<div>
			<CanvasJSChart options = {options} />
		</div>
    );
}
