import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';

export default function MainListItems(props) {
  return <Box component="div">
            {props.menuList.map((menu, idx) => 
                    <ListItem key={idx} button onClick={ () => props.onClick(idx) }>
                      <ListItemIcon>
                        {React.createElement(menu.icon, {})}
                      </ListItemIcon>
                      <ListItemText primary={menu.title} />
                    </ListItem>
            )}
        </Box>
}