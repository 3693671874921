import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Auth from "../../auth/Auth"
import LinearProgress from '@material-ui/core/LinearProgress';

export default function Authorizing(props) {

  const history = useHistory();

  useEffect( () => {
    let params = new URLSearchParams(props.location.search);
    Auth.getInst().requestLogin(params, (response) => {
      if(response.status === 200) {
          Auth.getInst().setLoginInfo(response.data)
          history.push("/dashboard");
      }
    })
  }, []); 

  return (
      <div className="App-login">
        Processing authorization...
        <LinearProgress />
      </div>
  ) 
}
