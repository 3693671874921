import React, { useRef, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import CategoryFilter from './CategoryFilter';
import DpLogTable from './DpLogTable';
import DpLogChart from './DpLogChart';
import { requestGet } from "../../util/RequestData"
import ServerInfo from "../../backend_info.json"
import Button from '@material-ui/core/Button';
import RecordDialog from "./RecordDialog"
import Permissions from '../../auth/Permissions'
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import RefreshButton from "../../components/RefreshButton"


const useStyles = makeStyles(theme => ({
    root: {
        display: "flex"
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    table: {
        marginTop: "100px"
    }
}));

function getItemList(key, data) {
    let list = []
    for (let i = 0, item; (item = data[i]); i++) {
        if (key in item) {
            list.push(item[key])
        }
    }

    return list.filter((a, b) => list.indexOf(a) === b)
}

function getFilteredData(filters, data) {
    let filteredList = []
    for (let dataIdx = 0, item; (item = data[dataIdx]); dataIdx++) {
        let filtered = true
        for (let key in filters) {
            if (filters[key].length > 0 && !filters[key].includes(item[key])) {
                filtered = false
            }
        }
        if (filtered) {
            filteredList.push(item)
        }
    }
    return filteredList
}


export default function DeploymentHistory() {
    const classes = useStyles();
    const [dpData, setDpData] = React.useState([])
    const [refreshSwitch, setRefreshSwitch] = React.useState(false)
    const [dataValid, setDataValid] = React.useState(false)
    const [requesting, setRequesting] = React.useState(false)
    const [recordDialogOpen, setRecordDialogOpen] = React.useState(false);
    const rawData = useRef([])
    const startDate = useRef(undefined)
    const endDate = useRef(undefined)
    const serviceFilter = useRef([])
    const regionFilter = useRef([])
    const env = useRef("sa")
    const selectedData = useRef({})
    const dialogMode = useRef("")

    const dpKeys = ["service_name", "deploy_time", "deployed_time", "deployed_env", "deployed_region", "app_version", "ami_name", "deploy_user", "recovery_mode", "recovery_of", "cd_test" ,"comment" ]

    const isVisible = () => {
        if (Permissions.getInst().hasPermission("dphistory.modify")) {
            return 'visible'
        }
        return 'hidden'
    }

    const handleClickOpen = () => {
        dialogMode.current = "new"
        selectedData.current = {}
        setRecordDialogOpen(true)
    }

    const handleClose = () => {
        setRecordDialogOpen(false)
    }

    const editHandler = (data) => {
        selectedData.current = data
        dialogMode.current = ""
        setRecordDialogOpen(true)
    }

    const initValues = () => {
        let today = new Date()
        let before30days = new Date()
        before30days.setDate(today.getDate() - 30)

        if (startDate.current === undefined) {
            startDate.current = before30days.toISOString().substring(0, 10)
        }
        if (endDate.current === undefined) {
            endDate.current = today.toISOString().substring(0, 10)
        }
    }

    const getScanParams = () => {
        let endDay = new Date(endDate.current)
        endDay.setDate(endDay.getDate() + 1)

        return {
            "from": startDate.current,
            "to": endDay.toISOString().substring(0, 10),
            "env": env.current
        }
    }

    const getEnvList = () => {
        let envs = ["sa", "sae"]
        if (Permissions.getInst().hasPermission("dphistory.preprod")) {
            envs = [...envs, "sas", "sad"]
        }
        return envs
    }

    const calcMTTR = () => {
        let min = 0
        let errorData = []
        for (let i = dpData.length - 1; i >= 0; i--) {
            let item = dpData[i]
            if (item['recovery_of'] !== "") {
                if(item['recovery_of'] !== undefined && item['deploy_time'] !== undefined ) {
                    errorData.push({
                        "key": "s",
                        "date": item['recovery_of']
                    })
                    errorData.push({
                        "key": "e",
                        "date": item['deploy_time']
                    })
                }
            }
        }

        errorData.sort(function (l, r) {
            return l['date'].localeCompare(r['date'])
        })
        console.log(errorData)
        let keyStack = []
        for (let idx in errorData) {
            let item = errorData[idx]
            if (item['key'] === 's') {
                keyStack.push(item)
            } else if (item['key'] === 'e') {
                let prevKey = keyStack.pop()
                if (keyStack.length === 0) {
                    let s = new Date(prevKey['date'])
                    let e = new Date(item['date'])
                    min += (e - s) / 1000 / 60
                }
            }
        }

        let hour = min / 60
        min = min % 60
        let day = hour / 24
        hour = hour % 24
        return String(parseInt(day)) + "(D) /" + String(parseInt(hour)) + "(H) /" + String(parseInt(min)) + "(M)"
    }

    const categoryFilters = [
        {
            "id": "ServiceEnv",
            "name": "Service filter",
            "multiple": true,
            "selectedItems": serviceFilter.current,
            "itemList": getItemList("service_name", rawData.current).sort(function (l, r) {
                return l.localeCompare(r)
            }),
            "onChangeListener": (o) => {
                serviceFilter.current = o.target.value
                updateDpData()
            },
        },
        {
            "id": "RegionEnv",
            "name": "Region filter",
            "multiple": true,
            "selectedItems": regionFilter.current,
            "itemList": getItemList("deployed_region", rawData.current).sort(function (l, r) {
                return l.localeCompare(r)
            }),
            "onChangeListener": (o) => {
                regionFilter.current = o.target.value
                updateDpData()
            },
        }
    ]

    const updateDpData = () => {
        let newDpdata = getFilteredData({
            "service_name": serviceFilter.current,
            "deployed_region": regionFilter.current,
        }, rawData.current)
        setDpData(newDpdata)
    }

    const getChartData = () => {
        let dataMap = {
            "normal" : {},
            "rollback" : {},
            "hotfix" : {}
        }
        let servicesMap = {}
        let servicesList = []

        for (let dataIdx = 0, item; (item = dpData[dataIdx]); dataIdx++) {
            let mode = "normal"
            if(item['recovery_mode'] === 'rollback') {
                mode = "rollback"
            } else if(item['recovery_mode'] === 'hotfix') {
                mode = "hotfix"
            }
            let dataSet = dataMap[mode]
            if(dataSet[item['service_name']] === undefined) {
                dataSet[item['service_name']] = 0
            }
            dataSet[item['service_name']] += 1
            servicesMap[item['service_name']] = undefined
        }

        for(let k in servicesMap) {
            servicesList.push(k)
        }
        servicesList.sort(function (l, r) {
            return r.localeCompare(l)
        })

        for(let key in dataMap) {
            let dataSet = dataMap[key]
            let listSet = []
            for(let i in servicesList) {
                let value = dataSet[servicesList[i]] === undefined ? 0 : dataSet[servicesList[i]]
                listSet.push({ y: value, label: servicesList[i]})
            }
            dataMap[key] = listSet
        }

        return dataMap
    }

    const onChangedStartDate = (o) => {
        startDate.current = o.target.value
        setDataValid(false)
    }

    const OnChangedEndDate = (o) => {
        endDate.current = o.target.value
        setDataValid(false)
    }

    const OnChangeEnv = (o) => {
        env.current = o.target.value
        setDataValid(false)
    }

    const OnRefreshClicked = () => {
        setRequesting(true)
        setRefreshSwitch(!refreshSwitch)
    }

    useEffect(() => {
        requestGet(ServerInfo.dphistory.path_v1_getHistory, getScanParams(), response => {
            if (response.status === 200) {
                setRequesting(false)
                setDataValid(true)
                let data = response.data
                data.sort(function (l, r) {
                    return r['deploy_time'].localeCompare(l['deploy_time'])
                })
                console.log(data)
                rawData.current = data
                updateDpData()
            }
        })
    }, [refreshSwitch]);

    initValues()

    return (

        <form className={classes.container} noValidate>
            <Box component="div" align="right" visibility={isVisible()}>
                <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                    Add new record
                </Button>
            </Box>
            <Box component="div">
                <FormControl className={classes.formControl}>
                    <InputLabel id="demo-controlled-open-select-label">Env</InputLabel>
                    <Select
                        id="env"
                        defaultValue={env.current}
                        onChange={OnChangeEnv}
                    >
                        {getEnvList().map(value => (
                            <MenuItem value={value}>{value}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    id="date"
                    label="Start date"
                    type="date"
                    defaultValue={startDate.current}
                    className={classes.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={onChangedStartDate}
                />
                <TextField
                    id="date"
                    label="End date"
                    type="date"
                    defaultValue={endDate.current}
                    className={classes.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={OnChangedEndDate}
                />
                <RefreshButton dataValid={dataValid} requesting={requesting} onClick={OnRefreshClicked} />
                <CategoryFilter items={categoryFilters} />
            </Box>
            <Box component="div" align="right">
                <TextField
                    label="MTTR(Mean time to Repair)"
                    id="outlined-size-normal"
                    variant="outlined"
                    InputProps={{
                        readOnly: true,
                        style: { textAlign: 'center' }
                    }}
                    value={calcMTTR()}
                />
            </Box>
            <DpLogChart data={getChartData()} />
            <hr/>
            <DpLogTable className={classes.table} keys={dpKeys} data={dpData} editHandler={editHandler} />
            <RecordDialog open={recordDialogOpen} closeRequest={handleClose} data={selectedData.current} mode={dialogMode.current} />
        </form>
    );
}
