import axios from "axios";


export function requestGet(url, param, response) {
  axios
    .get(url, {
      params: {
        ...param,
      }
    })
    .then(response)
    .catch(response);
}

export function requestPost(url, data, response, headers={ "Content-Type": "application/x-www-form-urlencoded" }) {
  axios({
    method: "post",
    headers: headers,
    url: url,
    data: JSON.stringify({
      ...data,
    })
  })
  .then(response)
  .catch(response);
}
