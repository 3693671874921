import React, { useEffect } from "react";
import Auth from "../../auth/Auth";
import LinearProgress from '@material-ui/core/LinearProgress';

export default function Login() {
  
  useEffect( () => {
      Auth.getInst().requestAuth()
  }, []);

  return (
    <div className="App-login">
        Moving to authentication server 
        <LinearProgress />
    </div>
  )
}
